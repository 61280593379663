import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import FITUser from "./FITUser";
import MeasurementUnits from "./MeasurementUnits";
import Version from "./Version";
import AppAccess from "./AppAccess";

const exportReducers = history => {
    return combineReducers({
        appAccess: AppAccess,
        FITUser: FITUser,
        version: Version,
        MeasurementUnits: MeasurementUnits,
        router: connectRouter(history),
    });
};

export default exportReducers;

