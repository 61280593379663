/*
	FITUSER REDUCER FOR MANAGING USER SETTINGS
*/
import {APP_VERSION} from "fit/system/FITConstants";

const startingAccessSettings = {
    sections: {}, //sections that the user has purchased access to (workout programs, personal programs...etc)
    //skins: [], Eventually have a list of skins the user has purchased
};


const AppAccess = (state = startingAccessSettings, action) =>{
    switch(action.type){
        default:
            return state;
    }
};

export default AppAccess;
