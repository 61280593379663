import React from 'react';
import {Button} from '@mui/material';
import {Block} from "@mui/icons-material";
import CloseButton from "./CloseButton";

const CancelButton=({onClick, disabled = false, fullWidth=false, size='medium', sx})=>{
    return (
        <Button
            fullWidth={fullWidth}
            size={size}
            sx={sx}
            color={'secondary'}
            variant={'contained'}
            disabled={disabled}
            onClick={onClick}
        ><Block/> Cancel</Button>
    )
}

export default CloseButton; //CancelButton;