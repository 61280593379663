import User from './User';
import {isEmpty, intToBool} from 'fit/system/UtilityFunctions';
import SecureConnect from "./SecureConnect";
import {MOMENT_DATE_FORMAT, MOMENT_DATE_TIME_FORMAT} from "./FITConstants";
import MeasurementUnits from "./MeasurementUnits";
import moment from "moment";
/*
    -Pull User settings from Redux Store
    -Convert dates, measurement units to desired units for the user
    -
*/

export class UserPreferences{
    constructor(dateColor = '#000') {
        const user = new User();
        const mUnits = new MeasurementUnits(false);
        const info = user.getUserInfo();
        const {preferences} = info;

        const emphasisStyle = {color: dateColor, fontWeight: 800, fontSize: '14px'};

        //Preference Formats/Options
        this.formats = {
            //Measurement Units
            massUnits : [
                {label: 'Kilograms (kg)', unitID: mUnits.KILOGRAM_ID},
                {label: 'Pounds (lb)', unitID: mUnits.POUND_ID}
            ],
            smallLengthUnits: [
                {label: 'Millimeters (mm)', unitID: mUnits.MILLIMETER_ID, maxValue: 2000},
                {label: 'Centimeters (cm)', unitID: mUnits.CENTIMETER_ID, maxValue: 200},
                {label: 'Inches (in)', unitID: mUnits.INCHES_ID, maxValue: 78},
            ],
            mediumLengthUnits: [
                {label: 'Meters (m)',unitID: mUnits.METER_ID, maxValue: 1500},
                {label: 'Yards (yds)', unitID: mUnits.YARD_ID, maxValue: 1500},
            ],
            longLengthUnits: [
                {label: 'Kilometers (km)',unitID: mUnits.KILOMETER_ID},
                {label: 'Miles (mi)', unitID: mUnits.MILE_ID},
                {label: 'Nautical Miles (NM)', unitID: mUnits.NAUTICAL_MILE_ID}
            ],
            //Formatting
            dateFormats: [
                {id: 2, label: 'MM-DD-YYYY', conversion: 'MM-DD-YYYY'},
                {id: 3, label: 'DD-MM-YYYY', conversion: 'DD-MM-YYYY'},
                {id: 1, label: 'YYYY-MM-DD', conversion: MOMENT_DATE_FORMAT},
                {id: 4, label: 'Mon Day, YR', conversion: 'MMM DD, YY'}
            ],
            numberFormats: [
                {id: 1, label: <span>1<span style={emphasisStyle}>,</span>250<span style={emphasisStyle}>.</span>75 (Comma separates thousands)</span>},
                {id: 2, label: <span>1<span style={emphasisStyle}>.</span>250<span style={emphasisStyle}>,</span>75 (Decimal separates thousands)</span>},
                /*
                {id: 1, label: '1,250.75: (1 Thousand [COMMA] 250 [PERIOD] 75)'},
                {id: 2, label: '1.250,75: (1 Thousand [PERIOD] 250 [COMMA] 75)'},

                 */
            ],
            timeFormats: [
                {id: 1, label: '12 Hour: 11:44 PM', conversion: 'hh:mm A'},
                {id: 2, label: '12 Hour: 11:44:22 PM', conversion: 'hh:mm:ss A'},
                {id: 3, label: '24 Hour: 23:44', conversion: 'HH:mm'},
                {id: 4, label: '24 Hour: 23:44:22 ', conversion: 'HH:mm:ss'},

            ],
            unitTypes: [
                {id: 1, label: 'Imperial Units: pounds, inches, miles'},
                {id: 2, label: 'Metric Units: kilograms, centimeters, kilometers'},
            ],

        }
        const metricUnitType = 2;

        //Init
        //THEME STUFF
        this.onboarded = 0;
        this.themeColorID = 1;
        //Personal
        this.dob = '1900-01-01';
        this.genderAtBirth = 0;
        this.genderLabelID = 0;

        //Security
        this['2fa'] = false;
        this.text2fa = false;
        this.pin = '';

        //Date/Time/Measurements
        this.unitType = 1;
        this.massUnit = mUnits.KILOGRAM_ID;
        this.smallLength = mUnits.CENTIMETER_ID;
        this.mediumLength = mUnits.METER_ID;
        this.longLength = mUnits.KILOMETER_ID;
        this.timezone = 1; //FIX THIS

        this.preferMetric = false;
        this.timeSetting = 1;
        this.dateSetting = 1;
        this.decimalChar = '.';
        this.commaChar = ',';

        //Workout Settings
        this.autoComplete = false;
        this.validateWeight = false;
        this.kgUnit = 1;
        this.lbUnit = 1;

        //Device Settings
        this.allowAltAccounts = 3;
        this.deviceUnitsLocked = false;
        this.automaticUpdates = true;
        const log = false;


        if(!isEmpty(preferences)) {
            //Personal Settings
            if(log) {
                console.log('USER PREFS', preferences);
                console.log('***************************************************');
            }

            this.onboarded = parseInt(info.onboarded);
            this.themeColorID = preferences.themeColorID;

            this.dob = preferences.dob;
            this.genderLabelID = preferences.genderLabelID;
            this.genderAtBirth = preferences.genderAtBirth;



            //Security
            this['2fa'] = preferences['2fa'];
            this.text2fa = preferences.text2fa;
            this.pin = preferences.pin;

            //Unit Types, Measurements
            const unitType = parseInt(preferences.unitType);
            this.unitType = unitType
            this.preferMetric = unitType === metricUnitType;
            //Time/Date
            this.timeSetting = preferences.timeFormat;
            this.dateSetting = preferences.dateFormat
            //Number Formatting
            const numberFormat = parseInt(preferences.numberFormat);
            this.numberFormat = numberFormat;
            if (parseInt(numberFormat) === 2) {
                this.decimalChar = ',';
                this.commaChar = '.'
            }

            //Measurement Units
            this.massUnit = preferences.massUnit;
            this.smallLength = preferences.smallLength;
            this.mediumLength = preferences.mediumLength;
            this.longLength = preferences.longLength;

            //console.log('DEVICE PREFS', 'UNIT LOCKED', preferences.deviceUnitsLocked, 'ALT ACCTS', preferences.deviceAllowAltAccounts);

            //Workout Settings
            this.RPERequired = true;
            this.autoCompleteWorkout = preferences.autoCompleteWorkout;
            this.validateWeight = preferences.validateWeight;
            this.kgUnit = preferences.kgUnit;
            this.lbUnit = preferences.lbUnit;
            //Device Settings
            this.allowAltAccounts = preferences.allowAltAccounts;
            this.unitsLocked = preferences.unitsLocked;
            this.automaticUpdates = intToBool(preferences.automaticUpdates);
            this.autoFillTeamWorkouts = true;

        }

        this.dateFormat = this.initPreferenceSetting(this.dateSetting, this.formats.dateFormats, 'conversion');
        this.timeFormat = this.initPreferenceSetting(this.timeSetting, this.formats.timeFormats, 'conversion');


        /*
        //Length Conversion Table provides min, max values for fromUnitID to ToUnitID
        {
          [fromUnitID]: {
            {smallValue: #}
            {larg
                #, med: #, large, #}
        }
        */
        /*
        //EVENTUALLY DELETE??? 2024-10-24
        //Max length for each unit before moving up to next increment
        this.maxlengthConversionTable = {
            [mUnits.INCHES_ID]: 84, //max inches before moving up to yards/meters
            [mUnits.FEET_INCHES_ID]: 9, //max feet/inches before moving up to yards/meters
            [mUnits.FEET_DECIMAL]: 9, //max feet/inches before moving up to yards/meters

            [mUnits.MILLIMETER_ID]: 2000, //max mm before moving up to yards/meters
            [mUnits.CENTIMETER_ID]: 200, //max cm before moving up to yards/meters
            [mUnits.DECIMETER_ID]: 20, //max dm before moving up to yards/meters

            [mUnits.YARD_ID]: 1000, //max yards before moving up to miles/kmm
            [mUnits.METER_ID]: 1000, //max m before moving up to miles/km
        //  [mUnits.MILE_ID]: 1, NOTHING CURRENTLY BEYOND MILES
        //  [mUnits.KILOMETER_ID]: 1, NOTHING CURRENTLY BEYOND KM
        //  [mUnits.NAUTICAL_MILE_ID]: 1, NOTHING CURRENTLY BEYOND NM
        }

        //Minimum length required before stepping down to the next unit
        this.minlengthConversionTable = {
            [mUnits.INCHES_ID]: 0, //max inches before moving up to yards/meters
            [mUnits.FEET_INCHES_ID]: 1, //max feet/inches before moving up to yards/meters
            [mUnits.FEET_DECIMAL]: 1, //max feet/inches before moving up to yards/meters

            [mUnits.MILLIMETER_ID]: 0, //max mm before moving up to yards/meters
            [mUnits.CENTIMETER_ID]: 0, //max cm before moving up to yards/meters
            [mUnits.DECIMETER_ID]: 1, //max dm before moving up to yards/meters

            [mUnits.YARD_ID]: 1, //max yards before moving up to miles/kmm
            [mUnits.METER_ID]: 1, //max m before moving up to miles/km
            [mUnits.MILE_ID]: 1, //NOTHING CURRENTLY BEYOND MILES
            [mUnits.KILOMETER_ID]: 1,// NOTHING CURRENTLY BEYOND KM
            [mUnits.NAUTICAL_MILE_ID]: 1,// NOTHING CURRENTLY BEYOND NM
        }
        */

        /*  MOMENT CONVERSIONS
            moment().format('MMMM Do YYYY, h:mm:ss a'); // October 31st 2023, 10:23:36 pm
            moment().format('dddd');                    // Tuesday
            moment().format("MMM Do YY");               // Oct 31st 23
            moment().format('YYYY [escaped] YYYY');     // 2023 escaped 2023
            moment().format();                          // 2023-10-31T22:23:45-04:00
            moment.locale();         // en
            moment().format('LT');   // 10:24 PM
            moment().format('LTS');  // 10:24:47 PM
            moment().format('L');    // 10/31/2023
            moment().format('l');    // 10/31/2023
            moment().format('LL');   // October 31, 2023
            moment().format('ll');   // Oct 31, 2023
            moment().format('LLL');  // October 31, 2023 10:24 PM
            moment().format('lll');  // Oct 31, 2023 10:24 PM
            moment().format('LLLL'); // Tuesday, October 31, 2023 10:24 PM
            moment().format('llll'); // Tue, Oct 31, 2023 10:24 PM

        */
    }
    initPreferenceSetting(valueID, group, key = 'label') {
        const set = group.find(item => parseInt(item.id) === parseInt(valueID));
        return set ? set[key] : 1;
    }
    getDecimalCharacter(){
        return this.decimalChar;
    }
    updatePreference(preference, value){
        if(this[preference] == null) {
            return;
        }
        console.log('UPDATING PREFERENCE :: ', preference, value);
        let sc = new SecureConnect('user.php', 'post');
        sc.setAction('updatePreferences');
        if(preference === 'pin' && (value === '' || value.length < 4)){
            //Die. No pin submitted.
            console.error('Pin blank or invalid. Try again.');
            return;
        }
        let transmitValue = value;
        if(value === true || value === false){
            transmitValue = value === true ? 1:0;
        }
        sc.setFormData({[preference]: transmitValue});
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //Update state with the new setting
                const user = new User();
                user.updatePreference(preference, value);
            }
        });
    }
    getOnboarded(){
        return parseInt(this.onboarded) === 1;
    }
    getLocalClientUTCDate(format = MOMENT_DATE_FORMAT){
        //Get the current client date and convert to UTC time YYYY-MM-DD;
        return moment().utc().format(format);
    }
    convertDate(dateValue){
        //Return the date in the user's preferred date format
        const newDate = this.convertServerDate(dateValue);
        //console.log('RECEIVED =', dateValue, 'NEW =', newDate);
        return newDate;
    }
    convertServerDate(dateValue){
        //Converts UTC Date/Time to Browser local time
        const timeExists = this.#checkTimeExists(dateValue);
        const format = timeExists ? `${this.dateFormat} ${this.timeFormat}` : this.dateFormat;
        const startingFormat = MOMENT_DATE_TIME_FORMAT;
        const startingValue = timeExists ? dateValue : `${dateValue}T12:00:00.000z`;
        //Set UTC Time and return local
        if(false) {
            console.log('-------------');
            console.log('CONVERTING DATE', startingValue);
            console.log('TIME EXISTS', timeExists);
            console.log('FORMATS ::', 'STARTING =', startingFormat, 'NEW FORMAT =', format);
        }
        return moment.utc(startingValue, startingFormat).local().format(format);
    }
    convertTime(timeValue){
         return moment(timeValue, '').format(this.timeFormat);
    }
    #checkTimeExists(dateString){
        //Determine if the time exists within a dateString
        //used for formatting a timestamp
        return dateString && dateString.includes(':');
    }
    convertToPreferredData(value, fromUnitID, precision = 3){
        /*
            -Receive the value, original unitID and precision
            -Determine measurement type
            -Calculate value based on user preferences
            -return new value and unit Data based on the user preferences
        */
        fromUnitID = parseInt(fromUnitID);
        const mUnits = new MeasurementUnits();
        let unitData = mUnits.getMeasurementUnitByID(fromUnitID);
        if(mUnits.isLength(fromUnitID)){
            const toUnitID = this.#determineLengthType(fromUnitID);
            //Convert to the desired unit
            //console.log('--LENGTH PREFERRED UNIT', fromUnitID, '=>', toUnitID);
            if(toUnitID !== fromUnitID){
                //console.log('CONVERT', fromUnitID, '=>', toUnitID, precision);
                value = mUnits.convertUnits(value, fromUnitID, toUnitID, precision);
                unitData = mUnits.getMeasurementUnitByID(toUnitID);
            }
        }
        if(mUnits.isMass(fromUnitID)){
            //CONVERT MASS/WEIGHT UNITS
            //console.log('--MASS PREFERRED UNIT', fromUnitID, '=>', this.massUnit);
            if(parseInt(this.massUnit) !== fromUnitID){
                value =  mUnits.convertUnits(value, fromUnitID, this.massUnit, precision);
                unitData = mUnits.getMeasurementUnitByID(this.massUnit);
            }
        }
        return {
            value: value,
            unitData: unitData
        }
    }
    #determineLengthType(fromUnitID){
        //Receive a length Unit ID
        //Determine the length size type (small, medium, large)
        //Return the user preferences measurementID associated with the length size type
        const mUnits = new MeasurementUnits();
        const unitData = mUnits.getMeasurementUnitByID(fromUnitID);
        const {measurementGroup} = unitData;
        const intGroup = parseInt(measurementGroup);
        let toUnitID;
        if(intGroup === 3){
            //Medium Unit
            toUnitID = this.mediumLength;
        } else if(intGroup === 4){
            //Large Unit
            toUnitID = this.longLength;
        } else{
            //small Unit
            toUnitID = this.smallLength;
        }
        //return the measurementID
        return parseInt(toUnitID);
    }
    getPreferredUnitFromTypeID(typeID){
        typeID = parseInt(typeID);
        const mUnits = new MeasurementUnits();
        //Determine the type based on the typeID
        //Get the preferred unit from the set up types
        const units = mUnits.getMeasurementUnitsByTypeID(typeID);
        if(units == null){
            return units;
        }
        const unit = units[0];
        return this.getPreferredUnit(unit.id);
    }
    getPreferredUnit(fromUnitID){
        const mUnits = new MeasurementUnits();
        const length = mUnits.isLength(fromUnitID);
        const mass = mUnits.isMass(fromUnitID);
        if(length){
            const toUnitID = this.#determineLengthType(fromUnitID);
            return mUnits.getMeasurementUnitByID(toUnitID);
        } else if(mass){
            return mUnits.getMeasurementUnitByID(this.massUnit);
        } else{
            //Time
            return mUnits.getMeasurementUnitByID(mUnits.TIME_ID);
        }
    }


    #getMeasurementType(measurementID){
        const mUnits = new MeasurementUnits();
        const {typeID} = mUnits.getMeasurementUnitByID(measurementID);
        return typeID;
    }
    getWeightUnitIncrement(measurementID){
        //Get the user preferences lbUnit or kgUnit for validating a weight/mass input
        const mUnits = new MeasurementUnits();
        if(this.validateWeight && mUnits.isMass(measurementID)){
            return parseInt(measurementID) === mUnits.POUND_ID ? this.lbUnit : this.kgUnit;
        } else{
            return 1;
        }
    }
    getWeightMeasurementPrecision(measurementID, defaultPrecision = 0, activeWorkout = false){
        //Returns the precision (in decimal places based on weight validation settings)
        const mUnits = new MeasurementUnits();
        measurementID = parseInt(measurementID);
        if(this.validateWeight && mUnits.isMass(measurementID)){
            const unit = `${this.getWeightUnitIncrement(measurementID)}`;
            const split = unit.split('.');
            const decimalPlaces =  split.length === 1 ? 0 : split[1].length;
            return decimalPlaces;
        } else if(mUnits.isTime(measurementID) && activeWorkout){
            return 2; //2 Decimal places for time in active workout interface
        } else {
            return defaultPrecision;
        }
    }
    convertNumber(value, defaultNumber = false){
        if(this.#checkStandardCharacters() === true){ //. = decimal , = comma
            //Standard number. Return value as is
            return value;
        } else {
            // Convert Number by swapping decimal and commas
            const decimal = defaultNumber ? '.' : this.decimalChar;
            const comma = defaultNumber ? ',' : this.commaChar;

            const findDecimal = defaultNumber ? this.decimalChar : '.';
            const findComma = defaultNumber ? this.commaChar : ',';
            const decSwap = ']+]';
            const comSwap = '[*[';
            const swap = `${value}`.replaceAll(findDecimal, decSwap).replaceAll(findComma, comSwap);
            return swap.replaceAll(decSwap, decimal).replaceAll(comSwap, comma);
        }
    }
    convertToDefaultNumber(value){
        return this.convertNumber(value, true);
    }
    #checkStandardCharacters(){
        return '.' === this.decimalChar && ',' === this.commaChar;
    }


    /*
    //WORK IN PROGRESS OR UNUSED
    //DELETE EVENTUALLY? 2024-10-24
    #calculateLengths(value, fromUnitID, precision = 3){

        const mUnits = new MeasurementUnits();

        const maxValue = this.maxlengthConversionTable[fromUnitID];
        const minValue = this.minlengthConversionTable[fromUnitID];

        console.log('MIN =', minValue, 'max =' , maxValue);


        const shortValue = mUnits.convertUnits(value, fromUnitID, this.smallLength, precision);
        const medValue = mUnits.convertUnits(value, fromUnitID, this.mediumLength, precision);
        const longValue = mUnits.convertUnits(value, fromUnitID, this.longLength, precision);

        const shortUnits = mUnits.getMeasurementUnitByID(this.smallLength);
        const medUnits = mUnits.getMeasurementUnitByID(this.mediumLength);
        const longUnits = mUnits.getMeasurementUnitByID(this.longLength);


        if(false) {
            console.log('CONVERT DISTANCE/LENGTH', value, fromUnitID);
            console.log('PREFERRED SHORT LENGTH = ', this.smallLength, '=>', shortValue, shortUnits.abbreviation);
            console.log('PREFERRED MED LENGTH = ', this.mediumLength, '=>', medValue, medUnits.abbreviation);
            console.log('PREFERRED LONG LENGTH = ', this.longLength, '=>', longValue, longUnits.abbreviation);
            console.log('-----------------');
        }



        return {value: 14, unitData: mUnits.getMeasurementUnitByID(this.smallLength)}
    }
    */
}

export default UserPreferences;