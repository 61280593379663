import React, {useEffect, useState, useMemo} from 'react';
import User from 'fit/system/User';
import {
    Typography,
    Button,
    Grid,
    TextField,
    Tooltip,
    FormControlLabel,
    Checkbox,
    FormLabel
} from "@mui/material";
import {
    MOMENT_DATE_FORMAT,
    TYPOGRAPHY_BODY_STYLE,
    URL_MY_PROFILE,
    PRODUCTION_STATUS, MOMENT_DATE_TIME_FORMAT, DESKTOP_MIN_HEIGHT
} from "fit/system/FITConstants";
import {Stepper, Step, StepLabel, StepContent} from "@mui/material";
import Header from 'fit/components/Header';
import SecureConnect from "fit/system/SecureConnect";
import Security from "./Preferences/Security";
import {getSpacing, isEmpty} from "fit/system/UtilityFunctions";
import UserFormats from "./Preferences/UserFormats";
import MeasurementUnitPreferences from "./Preferences/MeasurementUnitPreferences";
import PersonalAthleteInformation from "./Preferences/PersonalAthleteInformation";
import Alert from 'fit/components/Alert';
import Link from 'fit/components/System/Link';
import {ArrowBack, ArrowForward, Check} from "@mui/icons-material";
import PrivacySettings from "./Preferences/PrivacySettings";
import WorkoutSettings from "./Preferences/WorkoutSettings";
import TabSection from "../TabSection";
import {setPageTitle} from "fit/system/UtilityFunctions";
import UserPreferences from "fit/system/UserPreferences";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import MaskedInput from "../Form/MaskedInput";
import moment from "moment";
import {Navigate} from "react-router-dom";
import MeasurementUnits from "fit/system/MeasurementUnits";
import TermsAndConditions from "../LegalAgreements/TermsAndConditions";
import PrivacyPolicy from "../LegalAgreements/PrivacyPolicy";
import PaperMargin from "fit/components/PaperMargin";
import DeviceSettings from "./Preferences/DeviceSettings";


const maxDate = moment().subtract(13, 'year').format(MOMENT_DATE_FORMAT); //13 Years old
const minDate = moment().subtract(100, 'year').format(MOMENT_DATE_FORMAT); //100 year old

const userSchema = yup.object().shape({
    firstName: yup.string()
        .trim()
        .min(2,'First name should at least 2 characters long')
        .max(50,'The first name is too long')
        .required('First name required'),
    lastName: yup.string()
        .trim()
        .min(2,'Last name should at least 2 characters long')
        .max(50,'The last name is too long')
        .required('Last name required'),
    email: yup.string()
        .trim()
        .email('Invalid email')
        .required('Email required'),
    phone: yup.string()
        .trim()
        .min(14, 'Phone number is incomplete')
        .max(16, 'Phone number is too long'),
    DOB: yup.date()
        .min(minDate, 'Valid date of birth required')
        .max(maxDate, 'You must be a minimum of 13 years old'),
});


const initUser=(deviceMaster)=>{
    const user = new User();
    const mUnits = new MeasurementUnits(false);
    let d = user.getUserInfo();
    return {
        userID: d.userID,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        phone: d.phone,
        DOB: '',
        genderLabelID: 0,
        genderAtBirth: 0,
        //Security
        '2fa': false,
        text2fa: false,
        pin: '',
        displayPin: false,
        //Privacy
        userDirectory: true,
        globalDirectory: false,
        phoneAccess: false,
        emailAccess: true,
        //Workout Settings
        autoCompleteWorkout: true,
        validateWeight: true,
        lbUnit: 5,
        kgUnit: 1,
        //formats
        unitType: 1,
        dateFormat: 2,
        timeFormat: 1,
        numberFormat: 1,
        //measurement units
        massUnit: mUnits.KILOGRAM_ID,
        smallLength: mUnits.CENTIMETER_ID,
        mediumLength: mUnits.METER_ID,
        longLength: mUnits.KILOMETER_ID,
        //Agreements
        privacyChecked: false,
        privacyTimestamp: '', //timestamp UTC
        termsChecked: false,
        termsTimestamp: '', //timestamp UTC

    }
}

const initDevice=()=>{
    return {
        automaticUpdates: 1,
        unitsLocked: 1,
        allowAltAccounts: 0,
    }
}

const SubmitButton=({nextFn, backFn, backDisabled= false, buttonText, buttonType = 'button', disabled=false})=>{
    const backMessage = backDisabled ? 'Disabled: can\'t go back' : 'Go back to previous section';
    return (
        <Grid container spacing={1} sx={{marginTop: getSpacing(), marginBottom: getSpacing()}}>
            <Grid item xs={3} sm={2}>
                <Tooltip title={backMessage}>
                    <span><Button fullWidth color={'secondary'} variant={'contained'} onClick={backFn} disabled={backDisabled}><ArrowBack/></Button></span>
                </Tooltip>

            </Grid>
            <Grid item xs={9} sm={10}>
                <Button fullWidth
                 color={'primary'} disabled={disabled} variant={'contained'} onClick={nextFn} type={buttonType}>{buttonText}</Button>
            </Grid>
        </Grid>
    )
}


const UserOnboarding=({deviceMaster = false, callbackFn})=>{
    const init =()=>initUser(deviceMaster);
    const [step, setStep] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [onboardData, setOnboardData] = useState(init);
    const [deviceData, setDeviceData] = useState(initDevice);
    const [submittedPrefs, setSubmittedPrefs] = useState(init);

    const [genderLabelOptions, setGenderLabelOptions] = useState({birthGenderLabels: [], genderLabels: []})
    const [serverResponse, setServerResponse] = useState({display: false, status: 'info', headline: 'headline', message: 'message'});

    useEffect(()=>{
        //Reinitialize the form when the device master/userID changes
        console.log('INITIALIZING');
        init();
    },[deviceMaster])

    useEffect(()=>{
        let sc = new SecureConnect('system.php?action=getOnboardingLabels');
        sc.setDisplayNotifications(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setGenderLabelOptions(sc.getData(json));
            }
        })
    }, genderLabelOptions)

    const backStep=()=>{
        setStep(step-1);
    }
    const nextStep=()=>{
        setStep(step+1);
    }
    const handleInput=(name, value)=>{
        let data = {...onboardData};
        data[name] = value;
        console.log('HANDLE INPUT', name, '=>', value);
        setOnboardData(data);
    }
    const handleDeviceSetting=(name, value)=>{
        let dd = {...deviceData};
        dd[name] = value;
        setDeviceData(dd);
    }
    const submitValidated=()=>{
        nextStep();
    }
    const toggleDisplayPin=()=>{
        let oD = structuredClone(onboardData);
        oD.displayPin = !onboardData.displayPin;
        setOnboardData(oD);
    }
    const finalizePrefs=()=>{
        console.log('FINALIZING PREFS', submittedPrefs);
        user.updatePreferences(submittedPrefs, true);
    }
    const onboardDevice=()=>{
        if(callbackFn == null){
            console.error('NOTHING TO DO. NO CALLBACK');
            console.error('BE SURE TO PASS A CALLBACK FUNCTION TO HANDLE THE FORM DATA');
        }
        let data = {...onboardData, ...deviceData};
        console.log('SUBMITTING TO CALL BACK FN');
        callbackFn(data);
    }
    const submitPreferences=()=>{
        let sc = new SecureConnect('user.php', 'post');
        sc.setAction('onboardUser');
        let od = structuredClone(onboardData);
        let p = structuredClone(onboardData);
        od['2fa'] = onboardData['2fa'] ? 1 : 0;
        od.autoCompleteWorkout = onboardData.autoCompleteWorkout ? 1: 0;
        od.validateWeight = onboardData.validateWeight ? 1 : 0;
        od.text2fa = onboardData.text2fa ? 1 : 0;
        sc.setFormData(od);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //Update the user preferences within app state
                //setTabValue(1);
                const user = new User();
                //Update the preferences from the form
                console.log('FORM PREFS', p);
                let prefs = {
                    onboarded: true,
                    DOB: p.DOB,
                    genderAtBirth: p.genderAtBirth,
                    genderLabelID: p.genderLabelID,
                    autoCompleteWorkout: p.autoCompleteWorkout,
                    validateWeight: p.validateWeight,
                    lbUnit: p.lbUnit,
                    kgUnit: p.kgUnit,
                    unitType: p.unitType,
                    dateFormat: p.dateFormat,
                    timeFormat: p.timeFormat,
                    numberFormat: p.numberFormat,
                    '2fa': p['2fa'],
                    text2fa: p.text2fa,
                    pin: p.pin,

                    timezone: 1,
                    massUnit: p.massUnit,
                    smallLength: p.smallLength,
                    mediumLength: p.mediumLength,
                    longLength: p.longLength
                };
                //Update user preferences state
                setSubmittedPrefs(prefs);
                //Update interface
                setTabValue(1);
            } else{
                //error occurred.
                const data = sc.getData(json);
                console.log('DATA', data);
                setStep(3);
                const res = sc.getResponse(json);
                let headline, status;
                switch(sc.getResponseCode(json)){
                    case 2:
                        headline = 'Info? WTF ARE YOU DOING HERE?'
                        status = 'info';
                        break;
                    case 3:
                        headline = 'Something Unexpected Occurred';
                        status = 'warning';
                        break;
                    default:
                        headline = 'An Unexpected Failure Occurred';
                        status = 'error';


                        const errorSections = [
                            //user info
                            ['firstName','lastName','email','phone','genderLabelID','genderAtBirth','DOB','gender'],
                            //workout settings
                            ['autoCompleteWorkout','validateWeight','lbUnit','kgUnit'],
                            //security
                            ['2fa','pin'],
                            //measurement units
                            ['unitType','dateFormat','timeFormat','numberFormat']
                        ];

                        if(!isEmpty(data) && data.variableName != null){
                            const {variableName} = data;
                            const index = errorSections.findIndex(section => {console.log(variableName, '???', section); section.includes(variableName)});
                            console.log(data.variableName, '=>', index);
                        }
                        break;
                }
                const serverResponse = {
                    status: status,
                    display: true,
                    headline,
                    message: res.msg,
                }
                console.log('RESPONSE', res);
                setServerResponse(serverResponse);
            }
        })
    }

    const user = new User();
    if(user.getOnboarded() && PRODUCTION_STATUS){
        return (<Navigate to={URL_MY_PROFILE}/>);
    }

    const handleAgreement=(name, checked)=>{
        const userPrefs = new UserPreferences();
        const timestamp = checked ? userPrefs.getLocalClientUTCDate(MOMENT_DATE_TIME_FORMAT) : '';
        let od = {...onboardData};
        if(name === 'privacy'){
            od.privacyTimestamp = timestamp;
            od.privacyChecked = checked;
        } else{
            //terms & conditions
            od.termsChecked = checked;
            od.termsTimestamp = timestamp;
        }
        setOnboardData(od);
    }


    const getOnboardSteps=()=>{
        const userSteps = [
            <Step>
                <StepLabel>Terms & Conditions</StepLabel>
                <StepContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TabSection style={agreementStyles}>
                                <TermsAndConditions/>
                            </TabSection>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={oD.termsChecked} onClick={()=>handleAgreement('terms', !oD.termsChecked)}/>
                                    }
                                    label={'I agree to Buteo\'s Terms & Conditions'}
                                />
                            </FormLabel>
                            TS: {oD.termsTimestamp}
                        </Grid>
                        <Grid item xs={12}>
                            <SubmitButton
                                buttonText={'Continue'}
                                backDisabled={true}
                                nextFn={()=>nextStep()}
                                disabled={!oD.termsChecked}
                                backFn={()=>backStep()}
                            />
                        </Grid>
                    </Grid>
                </StepContent>
            </Step>,
            <Step>
                <StepLabel>Privacy Policy</StepLabel>
                <StepContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TabSection style={agreementStyles}>
                                <PrivacyPolicy/>
                            </TabSection>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={oD.privacyChecked} onClick={()=>handleAgreement('privacy', !oD.privacyChecked)}/>
                                    }
                                    label={'I agree to Buteo\'s Privacy Policy'}
                                />
                            </FormLabel>
                            TS: {oD.privacyTimestamp}

                        </Grid>
                        <Grid item xs={12}>
                            <SubmitButton
                                buttonText={'Continue'}
                                nextFn={()=>nextStep()}
                                disabled={!oD.privacyChecked}
                                backFn={()=>backStep()}
                            />
                        </Grid>
                    </Grid>

                </StepContent>
            </Step>,
            <Step>
                <StepLabel>User Account Information</StepLabel>
                <StepContent>
                    <div style={{marginBottom: getSpacing()}}>
                        <Typography variant={'h3'}>Basic Account Information</Typography>
                    </div>

                    <Formik
                        initialValues={oD}
                        validationSchema={userSchema}
                        onSubmit={(data)=>submitValidated(data)}
                        enableReinitialize={true}
                    >
                        {({errors, touched}) => (
                            <Form>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="firstName"
                                            label="First Name"
                                            error={errors.firstName && touched.firstName}
                                            helperText={errors.firstName || ' '}
                                            type="text"
                                            placeholder="Robert"
                                            onBlur={(e)=>handleInput('firstName',e.target.value)}
                                            defaultValue={oD.firstName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="lastName"
                                            label="Last Name"
                                            error={errors.lastName && touched.lastName}
                                            helperText={errors.lastName || ' '}
                                            type="text"
                                            placeholder="Paulsen"
                                            onBlur={(e)=>handleInput('lastName',e.target.value)}
                                            defaultValue={oD.lastName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="email"
                                            label="Email"
                                            error={errors.email && touched.email}
                                            helperText={errors.email || ' '}
                                            type="email"
                                            placeholder="RobertPaulsen@bodjour.com"
                                            onBlur={(e)=>handleInput('email',e.target.value)}
                                            defaultValue={oD.email}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaskedInput
                                            fullWidth
                                            pattern={"phone"}
                                            name={"phone"}
                                            label={"Phone"}
                                            error={errors.phone}
                                            helperText={errors.phone || ' '}
                                            placeholder="(888) 867-5309"
                                            onBlur={(e)=>handleInput('phone',e.target.value)}
                                            defaultValue={oD.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <Typography variant={'h3'}>Athlete Information</Typography>
                                <PersonalAthleteInformation
                                    errors={errors}
                                    touched={touched}
                                    DOB={oD.DOB}
                                    genderAtBirth={oD.genderAtBirth}
                                    gender={oD.genderLabelID}
                                    birthGenderLabels={genderLabelOptions.birthGenderLabels}
                                    genderLabels={genderLabelOptions.genderLabels}
                                    onChange={(name, value)=>handleInput(name, value)}
                                />
                                <SubmitButton
                                    backFn={()=>backStep()}
                                    backDisabled={false}
                                    nextFn={null}
                                    buttonType={'submit'}
                                    buttonText={'Continue'}
                                />
                            </Form>
                        )}
                    </Formik>

                </StepContent>
            </Step>
        ];
        const standardSteps = [
            <Step>
                <StepLabel>Security</StepLabel>
                <StepContent>
                    <Typography variant={'h3'}>Security Settings</Typography>
                    <Security
                        onboard={true}
                        twoFactor={oD['2fa']}
                        text2fa={oD.text2fa}
                        pin={oD.pin}
                        error={securityPinError}
                        pinError={pinErrorMessage}
                        displayPin={oD.displayPin}
                        setDisplayPin={()=>toggleDisplayPin()}
                        onChange={(name, value)=>handleInput(name, value)}
                    />

                    <SubmitButton
                        buttonText={'Continue'}
                        nextFn={()=>nextStep()}
                        disabled={securityDisabled}
                        backFn={()=>backStep()}
                        backDisabled={deviceMaster}
                    />
                </StepContent>
            </Step>,
            <Step>
                <StepLabel>Personal Workout Preferences</StepLabel>
                <StepContent>
                    <WorkoutSettings
                        deviceAccount={deviceMaster}
                        autoComplete={oD.autoCompleteWorkout}
                        validateWeight={oD.validateWeight}
                        lbUnit={oD.lbUnit}
                        kgUnit={oD.kgUnit}
                        onChange={(name, value)=>handleInput(name, value)}
                    />
                    <SubmitButton
                        buttonText={'continue'}
                        buttonType={'button'}
                        nextFn={()=>nextStep()}
                        backFn={()=>backStep()}
                    />
                </StepContent>
            </Step>,
            <Step>
                <StepLabel>Measurement Units</StepLabel>
                <StepContent>
                    <Typography variant={'h3'}>Unit Preferences & Date, Time, Number Formatting</Typography>
                    <MeasurementUnitPreferences
                        massUnit={oD.massUnit}
                        smallLength={oD.smallLength}
                        mediumLength={oD.mediumLength}
                        longLength={oD.longLength}
                        onChange={(name, value)=>handleInput(name, value)}
                    />
                    <SubmitButton
                        buttonText={'continue'}
                        buttonType={'button'}
                        nextFn={()=>nextStep()}
                        backFn={()=>backStep()}
                    />
                </StepContent>
            </Step>,
            <Step>
                <StepLabel>Data Formatting</StepLabel>
                <StepContent>
                    <Typography variant={'h3'}>Unit Preferences & Date, Time, Number Formatting</Typography>
                    <UserFormats
                        dateFormat={oD.dateFormat}
                        timeFormat={oD.timeFormat}
                        numberFormat={oD.numberFormat}
                        unitType={oD.unitType}
                        onChange={(name, value)=>handleInput(name, value)}
                    />
                    <SubmitButton backFn={()=>backStep()} nextFn={deviceMaster ? ()=>nextStep(): ()=>submitPreferences()} buttonText={deviceMaster ? 'Continue' : 'Complete Setup'} buttonType={'button'}/>
                </StepContent>
            </Step>
        ];
        const deviceSteps = [
            <Step>
                <StepLabel>
                    Device Settings
                </StepLabel>
                <StepContent>
                    <DeviceSettings
                        unitsLocked={deviceData.unitsLocked}
                        altAccounts={deviceData.allowAltAccounts}
                        automaticUpdates={deviceData.automaticUpdates}
                        onChange={(setting, value)=>handleDeviceSetting(setting, value)}
                    />
                    <SubmitButton backFn={()=>backStep()} nextFn={()=>onboardDevice()} buttonText={'Complete Setup'} buttonType={'button'}/>
                </StepContent>
            </Step>
        ]

        return deviceMaster ? standardSteps.concat(deviceSteps) : userSteps.concat(standardSteps);
    }



    const oD = onboardData;
    const securityPinError = oD.pin.length > 0 && oD.pin.length < 4;
    const securityDisabled = oD.pin.length < 4;
    const pinErrorMessage = securityPinError ? 'Pin must be at least 4 characters long': ' ';
    const agreementStyles = {overflow: 'scroll', maxHeight: DESKTOP_MIN_HEIGHT, overflowX: 'hidden'};

    const steps = getOnboardSteps();

    setPageTitle("Onboarding: Let's Finish Setting Up Your Account");
    return (
        <div>
            {!deviceMaster &&
                <Header header={'Onboarding'}/>
            }
            <PaperMargin>
                <Typography variant={'h2'}>Let's Finish Setting Up {deviceMaster? 'The Device' : 'Your'} Account</Typography>
                {tabValue === 0 &&
                    <TabSection paddingTop={false}>
                        <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>To get full access to Buteo's functions and features we need to set {deviceMaster ? 'default device' : 'up your user'} preferences.</Typography>
                        <Alert
                            visible={serverResponse.display}
                            status={serverResponse.status}
                            headline={serverResponse.headline}
                            message={serverResponse.message}
                        />
                        <Stepper orientation={'vertical'} activeStep={step}>
                            {steps}
                        </Stepper>
                    </TabSection>
                }
                {tabValue === 1 && deviceMaster === false &&
                    <TabSection paddingTop={false}>
                        <Alert
                            status={'success'}
                            visible={true}
                            headline={'Onboarding Finished!'}
                            message={'Account setup completed.'}
                        />
                        <div style={{paddingTop: getSpacing()}}>
                            <Typography variant={'h3'}>You're All Done</Typography>
                            <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>Your account is all set up and ready for use. You can visit your profile now. You can also update your settings later on as they can be found under the "<strong>App Settings</strong>" section of your profile.</Typography>
                            <Link to={URL_MY_PROFILE}><Button variant={'contained'} color={'primary'} onClick={()=>finalizePrefs()}>Take Me To My Profile <ArrowForward/></Button></Link>
                        </div>
                    </TabSection>
                }
            </PaperMargin>
        </div>
    )
}

export default UserOnboarding;

