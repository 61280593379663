import React,{lazy} from 'react';
import Modal from '../Dialogs/Modal';
import {
    Button
} from "@mui/material";
import User from 'fit/system/User';
import VersionControl from "fit/system/VersionControl";
import {Cancel, CheckCircle} from "@mui/icons-material";
import useAnnouncementStore from "./AnnouncementStore";
import {DISPLAY_OPTIONS} from "./AnnouncementStore";
import {boolToInt} from "fit/system/UtilityFunctions";
import SecureConnect from "fit/system/SecureConnect";
import CancelButton from "../InterfaceButtons/CancelButton";
const FormDeviceUpdate = lazy(()=>import("./FormDeviceUpdate"))
const FormAltAccountLogin = lazy(()=>import("./FormAltAccountLogin"));
const FormDeviceAdminLogin = lazy(()=>import("./FormDeviceAdminLogin"));

const AppAnnouncementModal=()=>{


    const modalHeader = useAnnouncementStore(s=>s.modalHeader);
    const {title, display, size, section} = modalHeader;
    const closeModal = useAnnouncementStore(s=>s.closeModal);
    const initForms = useAnnouncementStore(s=>s.initForms);
    const deviceLogin = useAnnouncementStore(s=>s.deviceLogin);
    const updateDeviceLogin = useAnnouncementStore(s=>s.updateDeviceLogin);


    const displayAltLogin = section === DISPLAY_OPTIONS.altLogin;
    const displayDeviceAdmin = section === DISPLAY_OPTIONS.deviceLogin;
    const displayUpdateNotice = section === DISPLAY_OPTIONS.updateNotice;


    const ignoreUpdate=()=>{
        const vc = new VersionControl();
        vc.ignoreUpdate();
        closeModal();
    }
    const loadAdmin = (login = false) => {
        const user = new User();
        const sc = new SecureConnect('devices.php', 'post');
        sc.setAction('loginMaster');
        sc.setFormData({
            deviceID: user.getUserID(),
            pin: deviceLogin.pin,
            login: boolToInt(login)
        })
        sc.connect().then(json => {
            console.log('ADMIN LOADED', sc.getCompleted(json));
            const completed = sc.getCompleted(json);
            let verified = false;
            if(!login && completed){
                verified = true;
                console.log('HEREEEEE');
            } else{
                initForms();
                closeModal();
            }
            updateDeviceLogin('adminVerified', verified);
        });
    }


    let submitButton = null;
    if(displayDeviceAdmin){
        const {pin, adminVerified} = deviceLogin;
        const pinError = pin.length > 0 && pin.length < 4;
        submitButton = <Button
            color={'primary'}
            variant={'contained'}
            disabled={pinError || pin === '' || adminVerified}
            onClick={()=>loadAdmin(false)}
        >
            <CheckCircle/> Submit
        </Button>
    }

    const clickFn = displayUpdateNotice ? ()=>ignoreUpdate() : ()=>closeModal();

    return (
        <Modal
            open={display}
            title={title}
            size={size}
            modalActions={
                <React.Fragment>
                    {submitButton}
                    <CancelButton onClick={clickFn}/>
                </React.Fragment>
            }
        >
            { //Update notice for device Accounts
                displayUpdateNotice &&
                <FormDeviceUpdate/>
            }


            { //ADMINISTRATOR PIN ENTRY FORM FOR DEVICE ACCOUNTS
              displayDeviceAdmin &&
              <FormDeviceAdminLogin/>
            }

            {//Login display for Alternate Accounts on Devices
                displayAltLogin &&
                <FormAltAccountLogin/>
            }
        </Modal>
    )
}

export default AppAnnouncementModal;